import React, { useEffect, useState } from "react";
import instance from "../BaseUrl/BaseUrl";
import useEncryption from "../EncryptData/EncryptData";
import { toast } from "react-hot-toast";
import MainTitle from "../MainTitle/MainTitle";
import { useSelector } from "react-redux";

const ABTHistory = () => {
  const historyData = useSelector((state) => state?.user?.historyData?.ABTStakingData)
  console.log("🚀 ~ ABTHistory ~ historyData:", historyData)

  const { encryptData, decryptData } = useEncryption();
  const walletAddress = localStorage.getItem("metamask");
  // const [historyData, sethistoryData] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("Stake")
  console.log("🚀 ~ History ~ selectedOption:", selectedOption)

  // console.log("🚀 ~ History ~ historyData:", historyData);

  // const historyData = async () => {
  //   const userId = localStorage.getItem("userId");

  //   try {
  //     const result = await instance.get(
  //       `/stakingDetails?walletAddress=${walletAddress}&userId=${userId}`
  //     );
  //     // console.log("🚀 ~ historyData ~ stakingDetails:", result);

  //     const results = decryptData(result.data.data);
  //     console.log("🚀 ~ historyData ~ results:", results)
  //     // console.log("🚀 ~ historyData ~ results:", results);
  //     sethistoryData(results?.data);
  //     if (results.status === true) {
  //       toast.success(results.message);
  //       // setbusdprice(results.data.price);
  //     } else {
  //       toast.error("Please connect wallet");
  //       // console.log("🚀 ~ historyData ~ results.message:", results.message);
  //     }
  //   } catch (err) { }
  // };
  // useEffect(() => {
  //   historyData();
  // }, []);

  // const handleChangeFilter = (event) => {
  //   // const matchMonth = historyData?.staking?.map((month) => month.duration);
  //   setFilter(event.target.value);
  //   const filteredData = historyData?.staking?.filter((items) => {
  //     return items?.duration === filter;
  //   });
  //   sethistoryData(filteredData);
  // };
  const filteredData = filter
    ? historyData?.ABTStaking?.filter(
      (item) => item?.duration === parseInt(filter)
    )
    : historyData?.ABTStaking;
  return (
    <>

      <div class="flex justify-center  my-10  shadow-sm rounded-lg" role="group">
        <button type="button" class={`px-4 bg-history py-2 text-sm font-medium bg-[#030149] text-white border-2 ${selectedOption === "Stake" ? " border-blue-500" : "border-gray-600"}`} onClick={() => setSelectedOption("Stake")}>
          Stake
        </button>
        <button type="button" class={`px-4 bg-history py-2 text-sm font-medium bg-[#030149] text-white border-2 ${selectedOption === "Claim" ? " border-blue-500" : "border-gray-600"}`} onClick={() => setSelectedOption("Claim")}>
          Claim
        </button>
        <button type="button" class={`px-4 bg-history py-2 text-sm font-medium bg-[#030149] text-white border-2 ${selectedOption === "UnStake" ? " border-blue-500" : "border-gray-600"}`} onClick={() => setSelectedOption("UnStake")}>
          UnStake
        </button>
      </div>

      <div className="lg:container lg:mx-auto mx-3   gap-5">
        {selectedOption === "Stake" && (
          <>
            <div className="lg:container lg:mx-auto md:mx-5 mx-3 ">
              <div className="mb-3">
                <MainTitle title={"Stake"} />
                {/* <div>
                      <label htmlFor="filter">Filter: </label>
                      <select value={filter} onChange={(e) => setFilter(e.target.value)}>
                        <option value="">All</option>
                        <option value="6">6 Months</option>
                        <option value="12">12 Months</option>
                      </select>
                    </div> */}
              </div>
              <table className="responsive-table border1">
                <thead>
                  <tr>
                    <th scope="col">Staked amount</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Estimated amount</th>
                    <th scope="col">Date joined</th>
                  </tr>
                </thead>

                <tbody>
                  {!filteredData?.length > 0 ? (
                    <tr>
                      <td data-title="Staked amount">_</td>
                      <td data-title="Duration">_</td>
                      <td data-title="Rewards">_</td>
                      <td data-title="Date joined">_</td>
                    </tr>
                  ) : (
                    filteredData?.map((items) => (
                      <>
                        <tr>
                          <td data-title="Staked amount">{items?.amount}</td>
                          <td data-title="Duration">{items?.duration} months</td>
                          <td data-title="estimatedValue">{items?.estimatedValue}</td>
                          <td data-title="Date joined">
                            {new Date(items?.createdAt)?.toLocaleDateString()}
                          </td>
                        </tr>
                      </>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        {
          selectedOption === "Claim" && (
            <div className="lg:container lg:mx-auto md:mx-5 mx-3 ">
              <div className="mb-3">
                <MainTitle title={"Claim"} />
                <div className="w-full flex justify-end">
                  {/* <label
              for="countries"
              className="block mb-2 text-sm font-medium text-white text-end"
            >
              Filter
            </label> */}
                  <select
                    // id="countries"
                    value={filter}
                    className="invisible bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  md:w-44 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="6">6 Months</option>
                    <option value="12">12 Months</option>
                  </select>
                </div>

                {/* <hr className="lg:w-[30%] w-full border-2 border-[#030239] border-dashed" />
          <h2 className="text-2xl font-bold text-white">Claim</h2>
          <hr className="mb-5 lg:w-[30%] w-full border-2 border-dashed border-[#030239]" /> */}
              </div>
              <table className="responsive-table border1">
                <thead>
                  <tr>
                    <th scope="col">Claim amount </th>
                    <th scope="col">Claim amount date</th>
                  </tr>
                </thead>

                <tbody>
                  {!historyData?.ABTwithdrawDetails?.length > 0 ? (
                    <tr>
                      <td data-title="Claim amount">_</td>
                      <td data-title="Claim amount date">_</td>
                    </tr>
                  ) : (
                    historyData?.ABTwithdrawDetails?.map((items) => (
                      <>
                        <tr>
                          <td data-title="Claim amount">{items?.amount}</td>

                          <td data-title="Claim amount date">
                            {new Date(items?.createdAt)?.toLocaleDateString()}
                          </td>
                        </tr>
                      </>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )
        }
        {selectedOption === "UnStake" && (
          <div className="lg:container lg:mx-auto md:mx-5 mx-3 ">
            <div className="mb-3">
              <MainTitle title={"Claim"} />
              <div className="w-full flex justify-end">
                {/* <label
              for="countries"
              className="block mb-2 text-sm font-medium text-white text-end"
            >
              Filter
            </label> */}
                <select
                  // id="countries"
                  value={filter}
                  className="invisible bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  md:w-44 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="6">6 Months</option>
                  <option value="12">12 Months</option>
                </select>
              </div>

              {/* <hr className="lg:w-[30%] w-full border-2 border-[#030239] border-dashed" />
          <h2 className="text-2xl font-bold text-white">Claim</h2>
          <hr className="mb-5 lg:w-[30%] w-full border-2 border-dashed border-[#030239]" /> */}
            </div>
            <table className="responsive-table border1">
              <thead>
                <tr>
                  <th scope="col">Transaction hash </th>

                  <th scope="col">Unstaked amount </th>
                  <th scope="col">Unstaked amount date</th>
                </tr>
              </thead>

              <tbody>
                {!historyData?.unstakedABTHistory?.length > 0 ? (
                  <tr>
                    <td data-title="Claim amount">_</td>
                    <td data-title="Claim amount">_</td>

                    <td data-title="Claim amount date">_</td>
                  </tr>
                ) : (
                  historyData?.unstakedABTHistory?.map((items) => (
                    <>
                      <tr>
                        <td data-title="Claim amount">{items?.txhash}</td>
                        <td data-title="Claim amount">{items?.unStakeWec}</td>

                        <td data-title="Claim amount date">
                          {new Date(items?.createdAt)?.toLocaleDateString()}
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default ABTHistory;
